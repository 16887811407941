import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';
import { hivemindAPI } from '../HivemindAPI';
import { autoGenerateQuestions } from './autoSlice';

const outcomesAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const questionsAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const commentsAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const strategiesAdapter = createEntityAdapter({
  sortComparer: (a, b) => sort_by_created_modified(a, b)
});
const evaluationsAdapter = createEntityAdapter();

const relevanceEvaluationsAdapter = createEntityAdapter();

const forecastsAdapter = createEntityAdapter();

const userForecastsAdapter = createEntityAdapter();
const outcomeExplanationsAdapter = createEntityAdapter();

const outcomeInitialState = outcomesAdapter.getInitialState({
  status: 'idle',
  error: null,
  sort: { key: 'Newest', order: 'asc', type: 'created' },
  filters: {
    own: false,
    user: { enabled: false, username: '' },
    generation: true,
    evaluation: false,
    forecasting: false,
    closed: false
  },
  dashboardOutcomeId: '',
  selectedDashboardQuestionIDs: [],
  showDashboardOthers: false
});
const questionInitialState = questionsAdapter.getInitialState({
  status: 'idle',
  error: null,
  sort: {
    question_score_table: { key: 'Rank', order: 'desc', type: 'rank' },
    evaluation_score_table: { key: 'Rank', order: 'desc', type: 'rank' }
  },
  actionStatus: 'idle'
});
const strategyInitialState = strategiesAdapter.getInitialState({
  status: 'idle',
  error: null
});
const commentInitialState = commentsAdapter.getInitialState();
const evaluationsInitialState = evaluationsAdapter.getInitialState();
const relevanceEvaluationsInitialState =
  relevanceEvaluationsAdapter.getInitialState();
const forecastInitialState = forecastsAdapter.getInitialState({
  status: 'idle',
  error: null
});
const userForecastInitialState = userForecastsAdapter.getInitialState({
  status: 'idle',
  error: null,
  filters: {
    own: false
  }
});
const outcomeExplanationsInitialState =
  outcomeExplanationsAdapter.getInitialState();
const initialState = {
  outcomes: outcomeInitialState,
  questions: questionInitialState,
  strategies: strategyInitialState,
  comments: commentInitialState,
  evaluations: evaluationsInitialState,
  relevanceEvaluations: relevanceEvaluationsInitialState,
  forecasts: forecastInitialState,
  userForecasts: userForecastInitialState,
  outcomeExplanations: outcomeExplanationsInitialState,
  selectedOutcome: null,
  selectedQuestion: null
};

export const fetchOutcomes = createAsyncThunk(
  'outcomes/fetchOutcomes',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/outcomes',
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchOutcomeById = createAsyncThunk(
  'outcomes/fetchOutcomeById',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/outcomes`,
      {
        id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestions = createAsyncThunk(
  'outcomes/fetchQuestions',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/questions',
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionsByOutcome = createAsyncThunk(
  'outcomes/fetchQuestionsByOutcome',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions`,
      {
        outcome_id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionsByActivity = createAsyncThunk(
  'outcomes/fetchQuestionsByActivity',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions/user/activity`,
      {
        user_id: payload.userId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionsByStatus = createAsyncThunk(
  'outcomes/fetchQuestionsByStatus',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions`,
      {
        status: payload.status
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchRejectedOrNonRepliedQuestions = createAsyncThunk(
  'outcomes/fetchRejectedOrNonRepliedQuestions',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions/rejected-or-non-replied`,
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchQuestionById = createAsyncThunk(
  'outcomes/fetchQuestionById',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/questions`,
      {
        id: payload.questionId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchStrategies = createAsyncThunk(
  'outcomes/fetchStrategies',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/strategies',
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchStrategiesByOutcome = createAsyncThunk(
  'outcomes/fetchStrategiesByOutcome',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/strategies`,
      {
        outcome_id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchStrategiesByActivity = createAsyncThunk(
  'outcomes/fetchStrategiesByActivity',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/strategies/user/activity`,
      {
        user_id: payload.userId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchStrategyById = createAsyncThunk(
  'outcomes/fetchStrategyById',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      `/api/strategies`,
      {
        id: payload.strategyId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchAllForecasts = createAsyncThunk(
  'outcomes/fetchAllForecasts',
  async () => {
    const response = await hivemindAPI.fetch(`/api/forecasts`);
    return response.json();
  }
);

export const fetchAllUserForecasts = createAsyncThunk(
  'outcomes/fetchAllUserForecasts',
  async () => {
    const response = await hivemindAPI.fetch(`/api/user-forecasts`);
    return response.json();
  }
);

export const fetchForecastsByQuestion = createAsyncThunk(
  'outcomes/fetchForecastsByQuestion',
  async (questionId) => {
    const response = await hivemindAPI.fetch(`/api/forecasts`, {
      question_id: questionId
    });
    return response.json();
  }
);

export const fetchUserForecastsByQuestion = createAsyncThunk(
  'outcomes/fetchUserForecastsByQuestion',
  async (questionId) => {
    const response = await hivemindAPI.fetch(`/api/user-forecasts`, {
      question_id: questionId
    });
    return response.json();
  }
);

export const fetchForecastsByOutcome = createAsyncThunk(
  'outcomes/fetchForecastsByOutcome',
  async (outcomeId) => {
    const response = await hivemindAPI.fetch(`/api/forecasts`, {
      outcome_id: outcomeId
    });
    return response.json();
  }
);

export const fetchUserForecastsByOutcome = createAsyncThunk(
  'outcomes/fetchUserForecastsByOutcome',
  async (outcomeId) => {
    const response = await hivemindAPI.fetch(`/api/user-forecasts`, {
      outcome_id: outcomeId
    });
    return response.json();
  }
);

export const addNewOutcome = createAsyncThunk(
  'outcomes/addNewOutcome',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/outcomes',
      {
        title: payload.title,
        description: payload.description,
        forecasting_start_date: payload.forecasting_start_date,
        end_date: payload.end_date,
        question_submission_limit: payload.question_submission_limit,
        generation_end_date: payload.generation_end_date,
        forecast_mechanism: payload.forecast_mechanism,
        continue_forecasting: payload.continue_forecasting,
        enable_AI_forecasting: payload.enable_AI_forecasting,
        crowd_can_view_strategies: payload.crowd_can_view_strategies,
        crowd_can_submit_strategies: payload.crowd_can_submit_strategies,
        owner_can_forecast: payload.owner_can_forecast,
        owner_can_question: payload.owner_can_question,
        bucket_preset_id: payload.bucket_preset_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewQuestion = createAsyncThunk(
  'outcomes/addNewQuestion',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/questions',
      {
        question_text: payload.question_text,
        resolution_criteria: payload.resolution_criteria,
        source: payload.source,
        resolution_date: payload.resolution_date,
        extra_info: payload.extra_info,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewStrategy = createAsyncThunk(
  'outcomes/addNewStrategy',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/strategies',
      {
        strategy: payload.strategy,
        strategy_date_preposition: payload.strategy_date_preposition,
        strategy_date_start: payload.strategy_date_start,
        strategy_date_end: payload.strategy_date_end,
        resolution_source: payload.resolution_source,
        link_authority: payload.link_authority,
        source_date_preposition: payload.source_date_preposition,
        resolution_date_start: payload.resolution_date_start,
        resolution_date_end: payload.resolution_date_end,
        extra_info: payload.extra_info,
        likelihood_prediction: payload.likelihood_prediction,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewStructuredQuestion = createAsyncThunk(
  'outcomes/addNewStructuredQuestion',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/questions/structured',
      {
        event: payload.event,
        state: payload.state,
        conditions: payload.conditions,
        resolution_date_preposition: payload.resolution_date_preposition,
        resolution_date_start: payload.resolution_date_start,
        resolution_date_end: payload.resolution_date_end,
        source: payload.source,
        link_authority: payload.link_authority,
        source_date_preposition: payload.source_date_preposition,
        source_date_start: payload.source_date_start,
        source_date_end: payload.source_date_end,
        relationship: payload.relationship,
        extra_info: payload.extra_info,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewComment = createAsyncThunk(
  'outcomes/addNewComment',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/comments',
      {
        question_id: payload.question_id,
        strategy_id: payload.strategy_id,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecastLink = createAsyncThunk(
  'outcomes/addNewForecastLink',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions/forecast_link',
      {
        question_id: payload.question_id,
        forecasting_source_link: payload.forecasting_source_link
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecast = createAsyncThunk(
  'outcomes/addNewForecast',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/forecasts',
      {
        probability: payload.probability,
        question_id: payload.questionId,
        forecast_date: payload.forecast_date
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewUserForecast = createAsyncThunk(
  'outcomes/addNewUserForecast',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/user-forecasts',
      {
        probability: payload.probability,
        question_id: payload.questionId,
        comment: payload.comment
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const submitLikelihoodPrediction = createAsyncThunk(
  'outcomes/submitLikelihoodPrediction',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/strategies/likelihood_prediction',
      {
        likelihood_prediction: payload.likelihood_prediction,
        strategy_id: payload.strategy_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewBucketUserForecast = createAsyncThunk(
  'outcomes/addNewBucketUserForecast',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/user-forecasts/bucket',
      {
        bucket_id: payload.bucketId,
        question_id: payload.questionId,
        comment: payload.comment
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewForecasts = createAsyncThunk(
  'outcomes/addNewForecasts',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/forecasts',
      {
        probability: payload.probabilities,
        question_id: payload.questionIds,
        forecast_date: payload.forecastDates
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewUserForecasts = createAsyncThunk(
  'outcomes/addNewUserForecasts',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/user-forecasts',
      {
        probability: payload.probabilities,
        question_id: payload.questionIds
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addNewCommentReply = createAsyncThunk(
  'outcomes/addNewCommentReply',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/comments',
      {
        parent_comment_id: payload.parentCommentId,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateOutcome = createAsyncThunk(
  'outcomes/updateOutcome',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/outcomes',
      {
        id: payload.id,
        new_title: payload.new_title,
        new_description: payload.description,
        new_question_submission_limit: payload.question_submission_limit,
        new_forecasting_start_date: payload.forecasting_start_date,
        new_end_date: payload.end_date,
        new_generation_end_date: payload.generation_deadline,
        new_bucket_preset: payload.new_bucket_preset,
        continue_forecasting: payload.continue_forecasting,
        owner_can_forecast: payload.owner_can_forecast,
        owner_can_question: payload.owner_can_question,
        new_crowd_can_view_strategies: payload.crowd_can_view_strategies,
        new_crowd_can_submit_strategies: payload.crowd_can_submit_strategies,
        new_groups: payload.groups,
        new_ai_forecasting: payload.ai_forecasting
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteOutcome = createAsyncThunk(
  'outcomes/deleteOutcome',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/outcomes',
      {
        id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateQuestion = createAsyncThunk(
  'outcomes/updateQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions',
      {
        id: payload.id,
        question_text: payload.question_text ? payload.question_text : null,
        question_score:
          payload.question_score !== null ? payload.question_score : null,
        resolution_criteria:
          payload.resolution_criteria !== null
            ? payload.resolution_criteria
            : null,
        source: payload.source !== null ? payload.source : null,
        resolution_date:
          payload.resolution_date !== null ? payload.resolution_date : null,
        extra_info: payload.extra_info !== null ? payload.extra_info : null,
        reset_pending:
          payload.reset_pending !== null ? payload.reset_pending : null,
        outcome_as_question:
          payload.outcome_as_question !== null
            ? payload.outcome_as_question
            : null
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateStrategy = createAsyncThunk(
  'outcomes/updateStrategy',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/strategies',
      {
        id: payload.id,
        strategy: payload.strategy,
        strategy_date_preposition: payload.strategy_date_preposition,
        strategy_date_start: payload.strategy_date_start,
        strategy_date_end: payload.strategy_date_end,
        resolution_source: payload.resolution_source,
        link_authority: payload.link_authority,
        source_date_preposition: payload.source_date_preposition,
        resolution_date_start: payload.resolution_date_start,
        resolution_date_end: payload.resolution_date_end,
        extra_info: payload.extra_info,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const editStructuredQuestion = createAsyncThunk(
  'outcomes/editStructuredQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions/structured',
      {
        id: payload.id,
        event: payload.event,
        state: payload.state,
        conditions: payload.conditions,
        resolution_date_preposition: payload.resolution_date_preposition,
        resolution_date_start: payload.resolution_date_start,
        resolution_date_end: payload.resolution_date_end,
        source: payload.source,
        link_authority: payload.link_authority,
        source_date_preposition: payload.source_date_preposition,
        source_date_start: payload.source_date_start,
        source_date_end: payload.source_date_end,
        relationship: payload.relationship,
        extra_info: payload.extra_info,
        outcome_as_question: payload.outcome_as_question,
        outcome_id: payload.outcome_id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const acceptQuestion = createAsyncThunk(
  'outcomes/acceptQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/accept`,
      {
        resolvable: payload.resolvable !== null ? payload.resolvable : null,
        relevant: payload.relevant !== null ? payload.relevant : null,
        duplicate: payload.duplicate !== null ? payload.duplicate : null
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const rejectQuestion = createAsyncThunk(
  'outcomes/rejectQuestion',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/reject`,
      {
        resolvable: payload.resolvable !== null ? payload.resolvable : null,
        relevant: payload.relevant !== null ? payload.relevant : null,
        duplicate: payload.duplicate !== null ? payload.duplicate : null,
        resolvable_rejection_comment_text:
          payload.resolvableRejectionComment !== null
            ? payload.resolvableRejectionComment
            : null,
        relevant_rejection_comment_text:
          payload.relevantRejectionComment !== null
            ? payload.relevantRejectionComment
            : null,
        duplicate_rejection_comment_text:
          payload.duplicateRejectionComment !== null
            ? payload.duplicateRejectionComment
            : null
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setQuestionAsDuplicate = createAsyncThunk(
  'outcomes/setQuestionAsDuplicate',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/duplicate`,
      {
        duplicate_of_id: payload.duplicateOfId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const submitForecastOverride = createAsyncThunk(
  'outcomes/submitForecastOverride',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/forecast_override`,
      {
        outcome_id: payload.outcome_id,
        forecast_override_list: JSON.stringify(payload.forecast_override_list)
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setQuestionResolutionImpact = createAsyncThunk(
  'outcomes/setQuestionResolutionImpact',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/resolution_impact`,
      {
        resolution_impact: payload.resolutionImpact
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setQuestionResolution = createAsyncThunk(
  'outcomes/setQuestionResolution',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.id}/resolution`,
      {
        resolution: payload.resolution,
        resolution_date: payload.resolutionDate
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const setStrategyResolution = createAsyncThunk(
  'outcomes/setStrategyResolution',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/strategies/${payload.id}/resolution`,
      {
        resolution: payload.resolution,
        resolution_date: payload.resolutionDate
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const makeRelevanceEvaluation = createAsyncThunk(
  'outcomes/makeRelevanceEvaluation',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/evaluations/relevance`,
      {
        joint_occurrence_forecast: payload.jointOccurrence,
        question_id: payload.id,
        question_forecast: payload.questionForecast,
        outcome_forecast: payload.outcomeForecast
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const makeRelevanceEvaluationOnStrategy = createAsyncThunk(
  'outcomes/makeRelevanceEvaluationOnStrategy',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/strategies/evaluations/relevance`,
      {
        joint_occurrence_forecast: payload.jointOccurrence,
        strategy_id: payload.id
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteQuestion = createAsyncThunk(
  'outcomes/deleteQuestion',
  async (payload) => {
    await hivemindAPI.delete(
      '/api/questions',
      {
        id: payload.id
      },
      payload.auth_token
    );
    return payload.id;
  }
);

export const deleteStrategy = createAsyncThunk(
  'outcomes/deleteStrategy',
  async (payload) => {
    await hivemindAPI.delete(
      '/api/strategies',
      {
        id: payload.id
      },
      payload.auth_token
    );
    return payload.id;
  }
);

export const deleteComment = createAsyncThunk(
  'outcomes/deleteComment',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/comments',
      {
        id: payload.commentId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteForecast = createAsyncThunk(
  'outcomes/deleteForecast',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/forecasts',
      {
        id: payload.forecastId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteUserForecast = createAsyncThunk(
  'outcomes/deleteUserForecast',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/user-forecasts',
      {
        id: payload.forecastId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const updateComment = createAsyncThunk(
  'outcomes/updateComment',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/comments',
      {
        id: payload.id,
        text: payload.text
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const addEvaluation = createAsyncThunk(
  'outcomes/addEvaluation',
  async (payload) => {
    const response = await hivemindAPI.put(
      '/api/questions/evaluations',
      {
        question_id: payload.questionId,
        evaluation_category: payload.evaluationCategory
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const deleteEvaluation = createAsyncThunk(
  'outcomes/deleteevaluation',
  async (payload) => {
    const response = await hivemindAPI.delete(
      '/api/questions/evaluations',
      {
        question_id: payload.questionId,
        user_id: payload.userId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const confirmForecastsComplete = createAsyncThunk(
  'outcomes/confirmForecastsComplete',
  async (payload) => {
    const response = await hivemindAPI.put(
      `/api/questions/${payload.questionId}/complete_forecasts`,
      {},
      payload.auth_token
    );
    return response.json();
  }
);

export const generateQuestions = createAsyncThunk(
  'outcomes/generateQuestions',
  async (payload, thunkAPI) => {
    const response = await thunkAPI.dispatch(autoGenerateQuestions(payload));
    return response;
  }
);

export const generateOutcomeExplanation = createAsyncThunk(
  'outcomes/generateOutcomeExplanation',
  async (payload) => {
    const response = await hivemindAPI.post(
      '/api/outcome-explanations',
      {
        outcome_id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const fetchOutcomeExplanations = createAsyncThunk(
  'outcomes/fetchOutcomeExplanations',
  async (payload) => {
    const response = await hivemindAPI.fetch(
      '/api/outcome-explanations',
      {
        outcome_id: payload.outcomeId
      },
      payload.auth_token
    );
    return response.json();
  }
);

export const outcomeSlice = createSlice({
  name: 'outcomes',
  initialState,
  reducers: {
    resetSelectedQuestion(state) {
      state.selectedQuestion = null;
      state.selectedQuestionStatus = 'idle';
      state.selectedQuestionError = null;
    },
    updateSelectedOutcome: {
      reducer(state, action) {
        state.selectedOutcome = action.payload;
      },
      prepare(id) {
        return {
          payload: {
            id: id
          }
        };
      }
    },
    updateSelectedQuestion: {
      reducer(state, action) {
        state.selectedQuestion = action.payload;
      },
      prepare(id) {
        return {
          payload: {
            id: id
          }
        };
      }
    },
    deselectOutcome: {
      reducer(state) {
        state.selectedOutcome = null;
      }
    },
    deselectQuestion: {
      reducer(state) {
        state.selectedQuestion = null;
      }
    },
    setOutcomesSort: {
      reducer(state, action) {
        state.outcomes.sort = {
          key: action.payload.key
            ? action.payload.key
            : state.outcomes.sort.key,
          order: action.payload.order
            ? action.payload.order
            : state.outcomes.sort.order,
          type: action.payload.type
            ? action.payload.type
            : state.outcomes.sort.type
        };
      }
    },
    setOutcomeFilters: {
      reducer(state, action) {
        if (action.payload.own !== undefined) {
          state.outcomes.filters.own = action.payload.own;
        }
        if (action.payload.user !== undefined) {
          state.outcomes.filters.user = action.payload.user;
        }
        if (action.payload.generation !== undefined) {
          state.outcomes.filters.generation = action.payload.generation;
        }
        if (action.payload.moderation !== undefined) {
          state.outcomes.filters.moderation = action.payload.moderation;
        }
        if (action.payload.evaluation !== undefined) {
          state.outcomes.filters.evaluation = action.payload.evaluation;
        }
        if (action.payload.forecasting !== undefined) {
          state.outcomes.filters.forecasting = action.payload.forecasting;
        }
        if (action.payload.closed !== undefined) {
          state.outcomes.filters.closed = action.payload.closed;
        }
      }
    },
    toggleOutcomeFilters: {
      reducer(state, action) {
        state.outcomes.filters = {
          own: action.payload.toggleUnseen
            ? !state.outcomes.filters.own
            : state.outcomes.filters.own,
          user: {
            enabled: action.payload.toggleUserEnabled
              ? !state.outcomes.filters.user.enabled
              : state.outcomes.filters.user.enabled,
            username:
              typeof action.payload.userUsername === 'string'
                ? action.payload.userUsername
                : state.outcomes.filters.user.username
          },
          generation: action.payload.toggleGeneration
            ? !state.outcomes.filters.generation
            : state.outcomes.filters.generation,
          moderation: action.payload.toggleModeration
            ? !state.outcomes.filters.moderation
            : state.outcomes.filters.moderation,
          evaluation: action.payload.toggleEvaluation
            ? !state.outcomes.filters.evaluation
            : state.outcomes.filters.evaluation,
          forecasting: action.payload.toggleForecasting
            ? !state.outcomes.filters.forecasting
            : state.outcomes.filters.forecasting,
          closed: action.payload.toggleClosed
            ? !state.outcomes.filters.closed
            : state.outcomes.filters.closed
        };
      }
    },
    setEvaluationDistributionFilter: {
      reducer(state, action) {
        if (action.payload.veryLow !== undefined) {
          state.outcomes.entities[
            action.payload.outcomeId
          ].questionEvaluationFilter.veryLow = action.payload.veryLow;
        }
        if (action.payload.low !== undefined) {
          state.outcomes.entities[
            action.payload.outcomeId
          ].questionEvaluationFilter.low = action.payload.low;
        }
        if (action.payload.medium !== undefined) {
          state.outcomes.entities[
            action.payload.outcomeId
          ].questionEvaluationFilter.medium = action.payload.medium;
        }
        if (action.payload.high !== undefined) {
          state.outcomes.entities[
            action.payload.outcomeId
          ].questionEvaluationFilter.high = action.payload.high;
        }
        if (action.payload.veryHigh !== undefined) {
          state.outcomes.entities[
            action.payload.outcomeId
          ].questionEvaluationFilter.veryHigh = action.payload.veryHigh;
        }
      }
    },
    setQuestionFilter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].questionFilter =
          action.payload.questionFilter;
      }
    },
    toggleQuestionFilter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].questionFilter = {
          pending: action.payload.pending
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .pending
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .pending,
          accepted: action.payload.accepted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .accepted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .accepted,
          rejected: action.payload.rejected
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .rejected
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .rejected,
          duplicate: action.payload.duplicate
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .duplicate
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .duplicate,
          submitted: action.payload.submitted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .submitted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .submitted,
          notSubmitted: action.payload.notSubmitted
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .notSubmitted
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .notSubmitted,
          closed: action.payload.closed
            ? !state.outcomes.entities[action.payload.outcomeId].questionFilter
                .closed
            : state.outcomes.entities[action.payload.outcomeId].questionFilter
                .closed
        };
      }
    },
    setQuestionSorter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].questionSorter =
          action.payload.sortValue;
      }
    },
    setStrategySorter: {
      reducer(state, action) {
        state.outcomes.entities[action.payload.outcomeId].strategySorter =
          action.payload.sortValue;
      }
    },
    setDashboardOutcomeId: {
      reducer(state, action) {
        state.outcomes.dashboardOutcomeId = action.payload.outcomeId;
      }
    },
    setQuestionsSortQuestionScore: {
      reducer(state, action) {
        state.questions.sort.question_score_table = {
          key: action.payload.key
            ? action.payload.key
            : state.questions.sort.question_score_table.key,
          order: action.payload.order
            ? action.payload.order
            : state.questions.sort.question_score_table.order,
          type: action.payload.type
            ? action.payload.type
            : state.questions.sort.question_score_table.type
        };
      }
    },
    setQuestionsSortEvaluationScore: {
      reducer(state, action) {
        state.questions.sort.evaluation_score_table = {
          key: action.payload.key
            ? action.payload.key
            : state.questions.sort.evaluation_score_table.key,
          order: action.payload.order
            ? action.payload.order
            : state.questions.sort.evaluation_score_table.order,
          type: action.payload.type
            ? action.payload.type
            : state.questions.sort.evaluation_score_table.type
        };
      }
    },
    setSelectedDashboardQuestionIDs: {
      reducer(state, action) {
        state.outcomes.selectedDashboardQuestionIDs = action.payload;
      }
    },
    toggleDashboardOtherQuestions: {
      reducer(state) {
        state.outcomes.showDashboardOthers =
          !state.outcomes.showDashboardOthers;
      }
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchOutcomes.pending, (state, action) => {
        state.outcomes.status = 'loading';
      })
      .addCase(fetchOutcomes.fulfilled, (state, action) => {
        state.outcomes.status = 'succeeded';
        let outcomes = action.payload.data.map((outcome) => {
          outcome.questionStatus = 'idle';
          outcome.forecastStatus = 'idle';
          outcome.strategyStatus = 'idle';
          outcome.continue_forecasting =
            outcome.continue_forecasting === 'True' ? true : false;
          outcome.owner_can_forecast =
            outcome.owner_can_forecast === 'True' ? true : false;
          outcome.owner_can_question =
            outcome.owner_can_question === 'True' ? true : false;
          outcome.questionFilter = {
            pending: true,
            accepted: true,
            rejected: false,
            submitted: false,
            notSubmitted: false,
            closed: false,
            duplicate: false
          };
          outcome.questionSorter = 1;
          outcome.strategySorter = 1;
          outcome.questionEvaluationFilter = {
            veryLow: false,
            low: false,
            medium: false,
            high: false,
            veryHigh: false
          };
          return outcome;
        });
        outcomesAdapter.upsertMany(state.outcomes, outcomes);
      })
      .addCase(fetchOutcomes.rejected, (state, action) => {
        state.outcomes.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchQuestions.pending, (state, action) => {
        state.questions.status = 'loading';
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.questions.status = 'succeeded';
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.questions.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchQuestionsByOutcome.pending, (state, action) => {
        if (state.outcomes.entities[action.meta.arg.outcomeId]) {
          state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
            'loading';
        }
      })
      .addCase(fetchQuestionsByOutcome.fulfilled, (state, action) => {
        if (state.outcomes.entities[action.meta.arg.outcomeId]) {
          state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
            'succeeded';
        }
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestionsByOutcome.rejected, (state, action) => {
        if (state.outcomes.entities[action.meta.arg.outcomeId]) {
          state.outcomes.entities[action.meta.arg.outcomeId].questionStatus =
            'failed';
        }
        state.questions.error = action.error.message;
      })
      .addCase(fetchStrategies.pending, (state, action) => {
        state.strategies.status = 'loading';
      })
      .addCase(fetchStrategies.fulfilled, (state, action) => {
        state.strategies.status = 'succeeded';
        reduceStrategies(state, action.payload.data);
      })
      .addCase(fetchStrategies.rejected, (state, action) => {
        state.strategies.status = 'failed';
        state.strategies.error = action.error.message;
      })
      .addCase(fetchStrategiesByOutcome.pending, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].strategyStatus =
          'loading';
      })
      .addCase(fetchStrategiesByOutcome.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].strategyStatus =
          'succeeded';
        reduceStrategies(state, action.payload.data);
      })
      .addCase(fetchStrategiesByOutcome.rejected, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcomeId].strategyStatus =
          'failed';
        state.strategies.error = action.error.message;
      })
      .addCase(fetchQuestionsByStatus.pending, (state, action) => {
        state.questions.status = 'loading';
      })
      .addCase(fetchQuestionsByStatus.fulfilled, (state, action) => {
        state.questions.status = 'succeeded';
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestionsByStatus.rejected, (state, action) => {
        state.questions.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchOutcomeById.fulfilled, (state, action) => {
        const updatedOutcome = action.payload.data;
        outcomesAdapter.updateOne(state.outcomes, {
          id: updatedOutcome.id,
          changes: updatedOutcome
        });
      })
      .addCase(fetchOutcomeById.rejected, (state, action) => {
        state.outcomes.error = action.error.message;
      })
      .addCase(fetchQuestionById.fulfilled, (state, action) => {
        state.selectedQuestionStatus = 'succeeded';
        reduceQuestions(state, [action.payload.data]);
        const question = action.payload.data;
        question.outcome_as_question =
          question.outcome_as_question === 'True' ? true : false;
        state.selectedQuestion = question;
      })
      .addCase(fetchQuestionById.rejected, (state, action) => {
        state.selectedQuestionStatus = 'failed';
        state.selectedQuestionError = action.error.message;
      })
      .addCase(fetchQuestionsByActivity.fulfilled, (state, action) => {
        reduceQuestions(state, action.payload.data);
      })
      .addCase(fetchQuestionsByActivity.rejected, (state, action) => {
        state.questions.error = action.error.message;
      })
      .addCase(fetchStrategyById.fulfilled, (state, action) => {
        state.strategies.status = 'succeeded';
        reduceStrategies(state, [action.payload.data]);
      })
      .addCase(fetchStrategyById.rejected, (state, action) => {
        state.strategies.status = 'failed';
        state.strategies.error = action.error.message;
      })
      .addCase(fetchStrategiesByActivity.fulfilled, (state, action) => {
        reduceStrategies(state, action.payload.data);
      })
      .addCase(fetchStrategiesByActivity.rejected, (state, action) => {
        state.strategies.error = action.error.message;
      })
      .addCase(fetchAllForecasts.pending, (state, action) => {
        state.forecasts.status = 'loading';
      })
      .addCase(fetchAllUserForecasts.pending, (state, action) => {
        state.userForecasts.status = 'loading';
      })
      .addCase(fetchAllForecasts.fulfilled, (state, action) => {
        state.forecasts.status = 'succeeded';
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchAllUserForecasts.fulfilled, (state, action) => {
        state.userForecasts.status = 'succeeded';
        userForecastsAdapter.upsertMany(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(fetchAllForecasts.rejected, (state, action) => {
        state.forecasts.error = action.error.message;
      })
      .addCase(fetchAllUserForecasts.rejected, (state, action) => {
        state.userForecasts.error = action.error.message;
      })
      .addCase(fetchForecastsByOutcome.pending, (state, action) => {
        state.outcomes.entities[action.meta.arg].forecastStatus = 'loading';
      })
      .addCase(fetchForecastsByOutcome.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg].forecastStatus = 'succeeded';
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchForecastsByOutcome.rejected, (state, action) => {
        state.outcomes.entities[action.meta.arg].forecastStatus = 'failed';
        state.forecasts.error = action.error.message;
      })
      .addCase(fetchUserForecastsByOutcome.fulfilled, (state, action) => {
        userForecastsAdapter.upsertMany(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(fetchUserForecastsByOutcome.rejected, (state, action) => {
        state.userForecasts.error = action.error.message;
      })
      .addCase(fetchForecastsByQuestion.fulfilled, (state, action) => {
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(fetchUserForecastsByQuestion.fulfilled, (state, action) => {
        userForecastsAdapter.upsertMany(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(fetchForecastsByQuestion.rejected, (state, action) => {
        state.forecasts.error = action.error.message;
      })
      .addCase(fetchUserForecastsByQuestion.rejected, (state, action) => {
        state.userForecasts.error = action.error.message;
      })
      .addCase(addNewOutcome.fulfilled, (state, action) => {
        let outcome = action.payload.data;
        outcome.questionStatus = 'idle';
        outcome.questionFilter = {
          pending: true,
          accepted: true,
          rejected: false,
          submitted: false,
          notSubmitted: false,
          closed: false,
          duplicate: false
        };
        outcome.questionSorter = 1;
        outcome.strategySorter = 1;
        outcome.questionEvaluationFilter = {
          veryLow: false,
          low: false,
          medium: false,
          high: false,
          veryHigh: false
        };
        outcome.continue_forecasting =
          outcome.continue_forecasting === 'True' ? true : false;
        outcome.owner_can_forecast =
          outcome.owner_can_forecast === 'True' ? true : false;
        outcome.owner_can_question =
          outcome.owner_can_question === 'True' ? true : false;
        outcomesAdapter.addOne(state.outcomes, outcome);
        if (state.outcomes.status !== 'succeeded') {
          state.outcomes.status = 'succeeded';
        }
      })
      .addCase(updateOutcome.fulfilled, (state, action) => {
        const updatedOutcome = action.payload.data;
        outcomesAdapter.updateOne(state.outcomes, {
          id: updatedOutcome.id,
          changes: updatedOutcome
        });
      })
      .addCase(deleteOutcome.fulfilled, (state, action) => {
        let outcomeId = action.meta.arg.outcomeId;
        outcomesAdapter.removeOne(state.outcomes, outcomeId);
      })
      .addCase(addNewQuestion.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].questionStatus =
          'succeeded';
        questionsAdapter.addOne(state.questions, action.payload.data);
      })
      .addCase(addNewStrategy.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].strategyStatus =
          'succeeded';
        strategiesAdapter.addOne(state.strategies, action.payload.data);
      })
      .addCase(addNewStructuredQuestion.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].questionStatus =
          'succeeded';
        questionsAdapter.addOne(state.questions, action.payload.data);
      })
      .addCase(updateQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(updateStrategy.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].strategyStatus =
          'idle';
        const updatedStrategy = action.payload.data;
        strategiesAdapter.updateOne(state.strategies, {
          id: updatedStrategy.id,
          changes: updatedStrategy
        });
      })
      .addCase(editStructuredQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        let questionId = action.meta.arg.id;
        questionsAdapter.removeOne(state.questions, questionId);
      })
      .addCase(deleteStrategy.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].strategyStatus =
          'idle';
        let strategyId = action.meta.arg.id;
        strategiesAdapter.removeOne(state.strategies, strategyId);
      })
      .addCase(acceptQuestion.pending, (state, action) => {
        state.questions.status = 'loading';
      })
      .addCase(acceptQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
        state.questions.status = 'idle';
      })
      .addCase(acceptQuestion.rejected, (state, action) => {
        state.questions.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(rejectQuestion.pending, (state, action) => {
        state.questions.status = 'loading';
      })
      .addCase(rejectQuestion.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data.question;
        const rejectionComments = action.payload.data.comments;
        for (var i = 0; i < rejectionComments.length; i++) {
          rejectionComments[i].parentQuestionId = updatedQuestion.id;
        }
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
        commentsAdapter.addMany(state.comments, rejectionComments);
        state.questions.status = 'idle';
      })
      .addCase(rejectQuestion.rejected, (state, action) => {
        state.questions.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(setQuestionAsDuplicate.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(submitForecastOverride.fulfilled, (state, action) => {
        const updatedQuestions = action.payload.data;
        questionsAdapter.upsertMany(state.questions, updatedQuestions);
      })
      .addCase(setQuestionResolutionImpact.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        if (updatedQuestion.outcome_owner_resolution_impact !== undefined) {
          updatedQuestion.outcome_owner_resolution_impact =
            updatedQuestion.outcome_owner_resolution_impact === 'True'
              ? true
              : false;
        }
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(makeRelevanceEvaluation.fulfilled, (state, action) => {
        const updatedRelevanceEvaluation = action.payload.data;
        updatedRelevanceEvaluation.question_id =
          updatedRelevanceEvaluation.parent?.id;
        updatedRelevanceEvaluation.user_id = updatedRelevanceEvaluation.user.id;
        relevanceEvaluationsAdapter.upsertOne(
          state.relevanceEvaluations,
          updatedRelevanceEvaluation
        );
      })
      .addCase(makeRelevanceEvaluationOnStrategy.fulfilled, (state, action) => {
        const updatedRelevanceEvaluation = action.payload.data;
        updatedRelevanceEvaluation.strategy_id =
          updatedRelevanceEvaluation.parent?.id;
        updatedRelevanceEvaluation.user_id = updatedRelevanceEvaluation.user.id;
        relevanceEvaluationsAdapter.upsertOne(
          state.relevanceEvaluations,
          updatedRelevanceEvaluation
        );
      })
      .addCase(fetchRejectedOrNonRepliedQuestions.pending, (state, action) => {
        state.questions.actionStatus = 'loading';
      })
      .addCase(
        fetchRejectedOrNonRepliedQuestions.fulfilled,
        (state, action) => {
          state.questions.actionStatus = 'succeeded';
          reduceQuestions(state, action.payload.data);
        }
      )
      .addCase(fetchRejectedOrNonRepliedQuestions.rejected, (state, action) => {
        state.questions.actionStatus = 'failed';
        state.questions.error = action.error.message;
      })
      .addCase(addNewForecastLink.fulfilled, (state, action) => {
        const updatedQuestion = action.payload.data;
        updatedQuestion.outcome_as_question =
          updatedQuestion.outcome_as_question === 'True' ? true : false;
        questionsAdapter.updateOne(state.questions, {
          id: updatedQuestion.id,
          changes: updatedQuestion
        });
      })
      .addCase(addNewComment.fulfilled, (state, action) => {
        let comment = action.payload.data;
        if (comment.is_deleted !== undefined) {
          comment.is_deleted = comment.is_deleted === 'True' ? true : false;
        }
        if (action.meta.arg.question_id)
          comment.parentQuestionId = action.meta.arg.question_id;
        if (action.meta.arg.strategy_id)
          comment.parentStrategyId = action.meta.arg.strategy_id;
        commentsAdapter.addOne(state.comments, comment);
      })
      .addCase(addNewCommentReply.fulfilled, (state, action) => {
        let comment = action.payload.data;
        if (comment.is_deleted !== undefined) {
          comment.is_deleted = comment.is_deleted === 'True' ? true : false;
        }
        comment.parentId = action.meta.arg.parentCommentId;
        commentsAdapter.addOne(state.comments, comment);
      })
      .addCase(deleteComment.fulfilled, (state, action) => {
        // This does not actually delete! Instead it archives, sets the is_deleted flag to true and returns the entity
        // This is the approach to solving HVMD-15
        const deletedComment = action.payload.data;
        if (deletedComment.active !== undefined) {
          deletedComment.active =
            deletedComment.active === 'True' ? true : false;
        }
        if (deletedComment.is_deleted !== undefined) {
          deletedComment.is_deleted =
            deletedComment.is_deleted === 'True' ? true : false;
        }
        commentsAdapter.updateOne(state.comments, {
          id: deletedComment.id,
          changes: deletedComment
        });
      })
      .addCase(updateComment.fulfilled, (state, action) => {
        const updatedComment = action.payload.data;
        if (updatedComment.active !== undefined) {
          updatedComment.active =
            updatedComment.active === 'True' ? true : false;
        }
        if (updatedComment.is_deleted !== undefined) {
          updatedComment.is_deleted =
            updatedComment.is_deleted === 'True' ? true : false;
        }
        commentsAdapter.updateOne(state.comments, {
          id: updatedComment.id,
          changes: updatedComment
        });
      })
      .addCase(addNewForecast.fulfilled, (state, action) => {
        forecastsAdapter.upsertOne(state.forecasts, action.payload.data);
      })
      .addCase(addNewForecasts.fulfilled, (state, action) => {
        forecastsAdapter.upsertMany(state.forecasts, action.payload.data);
      })
      .addCase(addNewUserForecast.fulfilled, (state, action) => {
        userForecastsAdapter.upsertOne(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(submitLikelihoodPrediction.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].strategyStatus =
          'idle';
        strategiesAdapter.upsertOne(state.strategies, action.payload.data);
      })
      .addCase(addNewBucketUserForecast.fulfilled, (state, action) => {
        userForecastsAdapter.upsertOne(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(addNewUserForecasts.fulfilled, (state, action) => {
        userForecastsAdapter.upsertMany(
          state.userForecasts,
          action.payload.data
        );
      })
      .addCase(deleteForecast.fulfilled, (state, action) => {
        forecastsAdapter.removeOne(state.forecasts, action.meta.arg.forecastId);
      })
      .addCase(deleteUserForecast.fulfilled, (state, action) => {
        userForecastsAdapter.removeOne(
          state.userForecasts,
          action.meta.arg.forecastId
        );
        commentsAdapter.removeMany(
          state.comments,
          state.comments.ids.filter(
            (commentId) =>
              state.comments.entities[commentId].forecast_id ===
              action.meta.arg.forecastId
          )
        );
      })
      .addCase(addEvaluation.fulfilled, (state, action) => {
        let evaluation = action.payload.data;
        evaluation.question_id = action.meta.arg.questionId;
        evaluationsAdapter.addOne(state.evaluations, evaluation);
      })
      .addCase(deleteEvaluation.fulfilled, (state, action) => {
        let questionId = action.meta.arg.questionId;
        let userId = action.meta.arg.userId;
        let evaluation = state.evaluations.entities.find(
          (evaluation) =>
            evaluation.user_id === userId &&
            evaluation.question_id === questionId
        );
        evaluationsAdapter.removeOne(state.evaluations, evaluation.id);
      })
      .addCase(confirmForecastsComplete.fulfilled, (state, action) => {
        reduceQuestions(state, [action.payload.data]);
      })
      .addCase(confirmForecastsComplete.rejected, (state, action) => {
        state.questions.error = action.error.message;
      })
      .addCase(generateQuestions.fulfilled, (state, action) => {
        if (action.payload.payload) {
          if (action.meta.arg.outcome_id) {
            state.outcomes.entities[action.meta.arg.outcome_id].questionStatus =
              'succeeded';
          }
          const questions = action.payload.payload.data.map((question) => {
            question.outcome_as_question =
              question.outcome_as_question === 'True' ? true : false;
            return question;
          });
          questionsAdapter.upsertMany(state.questions, questions);
        }
      })
      .addCase(setQuestionResolution.fulfilled, (state, action) => {
        questionsAdapter.upsertOne(state.questions, action.payload.data);
      })
      .addCase(setStrategyResolution.fulfilled, (state, action) => {
        state.outcomes.entities[action.meta.arg.outcome_id].strategyStatus =
          'idle';
        strategiesAdapter.upsertOne(state.strategies, action.payload.data);
      })
      .addCase(generateOutcomeExplanation.fulfilled, (state, action) => {
        outcomeExplanationsAdapter.addOne(
          state.outcomeExplanations,
          action.payload.data
        );
      })
      .addCase(fetchOutcomeExplanations.fulfilled, (state, action) => {
        outcomeExplanationsAdapter.upsertMany(
          state.outcomeExplanations,
          action.payload.data
        );
      });

    function processReplies(replies, parentCommentId) {
      return replies.map((reply) => ({
        ...reply,
        parentCommentId: parentCommentId,
        active: reply.active === 'True',
        is_deleted: reply.is_deleted === 'True',
        replies: reply.replies ? processReplies(reply.replies, reply.id) : []
      }));
    }

    function reduceQuestions(state, questionData) {
      let evaluations = [];
      let relevanceEvaluations = [];
      let commentArrays = [];
      let crowdForecasts = [];

      const processedQuestions = questionData.map((question) => {
        const processedQuestion = {
          ...question,
          outcome_owner_resolution_impact:
            question.outcome_owner_resolution_impact === 'True',
          outcome_as_question: question.outcome_as_question === 'True'
        };

        evaluations.push(
          ...question.evaluations?.map((evaluation) => ({
            ...evaluation,
            question_id: question.id
          }))
        );

        relevanceEvaluations.push(
          ...question.relevance_evaluations?.map((evaluation) => ({
            ...evaluation,
            question_id: question.id
          }))
        );

        commentArrays.push(
          ...question.comments.map((comment) => {
            const newComment = {
              ...comment,
              parentQuestionId: question.id,
              active: comment.active === 'True',
              is_deleted: comment.is_deleted === 'True',
              replies: comment.replies
                ? processReplies(comment.replies, comment.id)
                : []
            };
            return newComment;
          })
        );

        if (question.crowd_forecasts) {
          crowdForecasts.push(
            ...question.crowd_forecasts.map((forecast) => ({
              ...forecast,
              question_id: question.id
            }))
          );
        }

        return processedQuestion;
      });

      questionsAdapter.upsertMany(state.questions, processedQuestions);
      evaluationsAdapter.upsertMany(state.evaluations, evaluations);
      relevanceEvaluationsAdapter.upsertMany(
        state.relevanceEvaluations,
        relevanceEvaluations
      );

      let normalisedComments = [];
      commentArrays.forEach((comment) => {
        const relationisedComment = relationiseCommentReplies(comment);
        normalisedComments.push(...flattenComment(relationisedComment));
      });
      commentsAdapter.upsertMany(state.comments, normalisedComments);
      forecastsAdapter.upsertMany(state.forecasts, crowdForecasts);
    }
    function reduceStrategies(state, strategyData) {
      let commentArrays = [];
      let relevanceEvaluations = [];

      const processedStrategies = strategyData.map((strategy) => {
        const processedStrategy = {
          ...strategy
        };

        relevanceEvaluations.push(
          ...strategy.relevance_evaluations?.map((evaluation) => ({
            ...evaluation,
            strategy_id: strategy.id
          }))
        );

        commentArrays.push(
          ...strategy.comments.map((comment) => {
            const newComment = {
              ...comment,
              parentStrategyId: strategy.id,
              active: comment.active === 'True',
              is_deleted: comment.is_deleted === 'True',
              replies: comment.replies
                ? processReplies(comment.replies, comment.id)
                : []
            };
            return newComment;
          })
        );

        return processedStrategy;
      });

      strategiesAdapter.upsertMany(state.strategies, processedStrategies);
      relevanceEvaluationsAdapter.upsertMany(
        state.relevanceEvaluations,
        relevanceEvaluations
      );
      let normalisedComments = [];
      commentArrays.forEach((comment) => {
        comment = relationiseCommentReplies(comment);
        normalisedComments.push(...flattenComment(comment));
      });
      commentsAdapter.upsertMany(state.comments, normalisedComments);
    }
  }
});

export const {
  updateSelectedOutcome,
  updateSelectedQuestion,
  deselectOutcome,
  deselectQuestion,
  setOutcomesSort,
  setQuestionsSortEvaluationScore,
  setQuestionsSortQuestionScore,
  setOutcomeFilters,
  setQuestionFilter,
  toggleQuestionFilter,
  toggleOutcomeFilters,
  setEvaluationDistributionFilter,
  resetSelectedQuestion,
  setQuestionSorter,
  setStrategySorter,
  setDashboardOutcomeId,
  setSelectedDashboardQuestionIDs,
  toggleDashboardOtherQuestions
} = outcomeSlice.actions;

export default outcomeSlice.reducer;

export const {
  selectAll: selectAllOutcomes,
  selectById: selectOutcomeById,
  selectIds: selectOutcomeIds
} = outcomesAdapter.getSelectors((state) => state.outcomes.outcomes);

export const {
  selectAll: selectAllQuestions,
  selectById: selectQuestionById,
  selectIds: selectQuestionIds
} = questionsAdapter.getSelectors((state) => state.outcomes.questions);

export const {
  selectAll: selectAllStrategies,
  selectById: selectStrategyById,
  selectIds: selectStrategyIds
} = strategiesAdapter.getSelectors((state) => state.outcomes.strategies);

export const {
  selectAll: selectAllComments,
  selectById: selectCommentById,
  selectIds: selectCommentIds
} = commentsAdapter.getSelectors((state) => state.outcomes.comments);

export const {
  selectAll: selectAllEvaluations,
  selectById: selectEvaluationById,
  selectIds: selectEvaluationIds
} = evaluationsAdapter.getSelectors((state) => state.outcomes.evaluations);

export const {
  selectAll: selectAllRelevanceEvaluations,
  selectById: selectRelevanceEvaluationById,
  selectIds: selectRelevanceEvaluationIds
} = relevanceEvaluationsAdapter.getSelectors(
  (state) => state.outcomes.relevanceEvaluations
);

export const {
  selectAll: selectAllForecasts,
  selectById: selectForecastById,
  selectIds: selectForecastIds
} = forecastsAdapter.getSelectors((state) => state.outcomes.forecasts);

export const {
  selectAll: selectAllUserForecasts,
  selectById: selectUserForecastById,
  selectIds: selectUserForecastIds
} = userForecastsAdapter.getSelectors((state) => state.outcomes.userForecasts);

export const {
  selectAll: selectAllOutcomeExplanations,
  selectById: selectOutcomeExplanationById,
  selectIds: selectOutcomeExplanationIds
} = outcomeExplanationsAdapter.getSelectors(
  (state) => state.outcomes.outcomeExplanations
);

export const selectQuestionIdsByOutcome = (state, outcomeId) => {
  const questions = selectAllQuestions(state);
  const questionIds = questions
    .filter((question) => question.outcome_id === outcomeId)
    .map((question) => question.id);
  return questionIds;
};

export const selectQuestionsByIDs = createSelector(
  [selectAllQuestions, (state, questionIDs) => questionIDs],
  (questions, questionIDs) =>
    questionIDs
      ? questions.filter((question) => questionIDs.includes(question.id))
      : []
);

export const selectQuestionsByOutcome = createSelector(
  [selectAllQuestions, (state, outcomeId) => outcomeId],
  (questions, outcomeId) =>
    questions.filter((question) => question.outcome_id === outcomeId)
);

export const selectStrategiesByOutcome = createSelector(
  [selectAllStrategies, (state, outcomeId) => outcomeId],
  (strategies, outcomeId) =>
    strategies.filter((strategy) => strategy.outcome_id === outcomeId)
);

export const selectResolutionImpactQuestionsOfOutcome = createSelector(
  [selectAllQuestions, (state, outcomeId) => outcomeId],
  (questions, outcomeId) =>
    questions.filter(
      (question) =>
        question.outcome_id === outcomeId &&
        (question.outcome_owner_resolution_impact !== undefined ||
          question.group_resolution_impact !== undefined ||
          question.relationship !== undefined)
    )
);

export const selectQuestionsByUserId = createSelector(
  [selectAllQuestions, (state, userId) => userId],
  (questions, userId) =>
    questions.filter((question) => question.created_by.id === userId)
);

export const selectStrategiesByUserId = createSelector(
  [selectAllStrategies, (state, userId) => userId],
  (strategies, userId) =>
    strategies.filter((strategy) => strategy.created_by.id === userId)
);

export const selectQuestionsByAction = createSelector(
  [selectQuestionsByUserId, (state, userId) => userId],
  (questions) =>
    questions.filter((question) =>
      question.comments.some((comment) => comment.replies.length === 0)
    )
);

// export const selectCurrentCrowdForecast = createSelector(
//   [selectQuestionById, (state, questionId) => questionId],
//   (question) => {
//     var sorted_crowd_forecasts = question.crowd_forecasts?.toSorted(
//       (cfA, cfB) =>
//         cfA.created_at < cfB.created_at
//           ? 1
//           : cfB.created_at < cfA.created_at
//           ? -1
//           : 0
//     );
//     return sorted_crowd_forecasts ? sorted_crowd_forecasts[0] : undefined;
//   }
// );

export const selectOutcomesByUserId = createSelector(
  [selectAllOutcomes, (state, userId) => userId],
  (outcomes, userId) =>
    outcomes.filter((outcome) => outcome.created_by.id === userId)
);

export const selectOutcomeByQuestionId = createSelector(
  [selectAllOutcomes, (state, questionId) => questionId],
  (outcomes, questionId) =>
    outcomes.find((outcome) =>
      outcome?.questions?.some((question) => question.id === questionId)
    )
);

export const selectOutcomeByStrategyId = createSelector(
  [selectAllOutcomes, (state, strategyId) => strategyId],
  (outcomes, strategyId) =>
    outcomes.find((outcome) =>
      outcome?.strategies?.some((strategy) => strategy.id === strategyId)
    )
);

export const selectOutcomeByQuestionOrStrategyId = createSelector(
  [selectAllOutcomes, (state, props) => props],
  (outcomes, props) => {
    if (props.strategyId) {
      return outcomes.find((outcome) =>
        outcome?.strategies?.some(
          (strategy) => strategy.id === props.strategyId
        )
      );
    } else if (props.questionId) {
      return outcomes.find((outcome) =>
        outcome?.questions?.some((question) => question.id === props.questionId)
      );
    } else return null;
  }
);

export const selectCurrentUserForecastByQuestion = createSelector(
  [selectAllUserForecasts, (state, props) => props],
  (forecasts, props) =>
    forecasts.find(
      (forecast) =>
        forecast.created_by.id === props.userId &&
        forecast.question_id === parseInt(props.questionId) &&
        forecast.state.toLowerCase() === 'current'
    )
);

export const selectCurrentUserForecastsByUser = createSelector(
  [selectAllUserForecasts, (state, userId) => userId],
  (forecasts, userId) => {
    const result = forecasts.filter(
      (forecast) =>
        forecast.created_by.id === userId && forecast.state === 'Current'
    );
    return result;
  }
);

export const selectStandardCommentIdsByQuestion = (state, questionId) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId && !comment.comment_type
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectStandardCommentIdsByStrategy = (state, strategyId) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentStrategyId === strategyId && !comment.comment_type
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectRejectionCommentIdsByQuestion = (state, questionId) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId &&
        comment.comment_type &&
        comment.comment_type.includes('rejection') &&
        comment.active
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectInactiveRejectionCommentIdsByQuestion = (
  state,
  questionId
) => {
  const comments = selectAllComments(state);
  const commentIds = comments
    .filter(
      (comment) =>
        comment.parentQuestionId === questionId &&
        comment.comment_type &&
        comment.comment_type.includes('rejection') &&
        !comment.active
    )
    .map((comment) => comment.id);
  return commentIds;
};

export const selectReplyIdsByComment = (state, commentId) => {
  const comments = selectAllComments(state);
  const replyIds = comments
    .filter((comment) => comment.parentId === commentId)
    .map((comment) => comment.id);
  return replyIds;
};

export const selectCommentsByUserId = createSelector(
  [selectAllComments, (state, userId) => userId],
  (comments, userId) =>
    comments?.filter((comment) => comment?.created_by?.id === userId)
);

export const selectOutcomeByTitle = createSelector(
  [selectAllOutcomes, (state, title) => title],
  (outcomes, title) =>
    outcomes.find(
      (outcome) => getPlainText(outcome.title) === getPlainText(title)
    )
);

export const selectOutcomesByStatus = createSelector(
  [selectAllOutcomes, (state, status) => status],
  (outcomes, status) =>
    outcomes?.filter((outcome) => outcome.statuses.includes(status))
);

export const selectQuestionsByStatus = createSelector(
  [selectAllQuestions, (state, status) => status],
  (questions, status) =>
    questions?.filter((questions) => questions.status === status)
);

export const selectFilteredQuestionsByStatus = createSelector(
  [selectAllQuestions, (state, status) => status],
  (questions, status) =>
    questions?.filter((question) => {
      if (status === 'pending') {
        return (
          question.resolvable === undefined || question.relevant === undefined
        );
      } else if (status === 'unresolved') {
        return (
          ['Accepted', 'Submitted'].includes(question.status) &&
          (question.current_resolution === undefined ||
            question.current_resolution.name === 'Not Set')
        ); // its important we can resolve questions in pending status, but we can do that through the individual Question button. For the purposes of the resolution moderation page we only want questions that are accepted or submitted
      } else {
        return false;
      }
    })
);

export const selectForecastsByOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllForecasts,
    selectAllQuestions,
    (state, outcomeId) => outcomeId
  ],
  (state, forecasts, questions, outcomeId) => {
    forecasts.filter((forecast) => {
      let question = questions.find(
        (question) => question.id === forecast.question_id
      );
      if (question) {
        return outcomeId === question.outcome_id;
      } else {
        return false;
      }
    });
  }
);

export const selectUserForecastsByQuestion = (state, questionId) => {
  const forecasts = selectAllUserForecasts(state);
  return forecasts.filter((forecast) => forecast.question_id === questionId);
};

export const selectResolutionImpactForecastsOfOutcome = (state, outcomeId) => {
  const forecasts = selectAllForecasts(state);
  const questions = selectResolutionImpactQuestionsOfOutcome(state, outcomeId);
  return forecasts
    .filter((forecast) => {
      return questions.map((q) => q.id).includes(forecast.question_id);
    })
    .sort((forecastA, forecastB) =>
      forecastA.forecast_week > forecastB.forecast_week
        ? 1
        : forecastB.forecast_week > forecastA.forecast_week
        ? -1
        : 0
    );
};

export const selectedOutcomeBeliefs = (state) => {
  if (state.outcomes.selectedOutcome) {
    var selectedOutcome = selectOutcomeById(
      state,
      state.outcomes.selectedOutcome.id
    );
    return selectedOutcome?.beliefs;
  } else {
    return [];
  }
};

export const selectLatestOutcomeBelief = (state, outcomeId) => {
  var outcome = selectOutcomeById(state, outcomeId);
  var sortedBeliefs = outcome?.beliefs?.toSorted(function (a, b) {
    return new Date(b.beliefDate) - new Date(a.BeliefDate);
  });
  if (sortedBeliefs?.length > 0) {
    return sortedBeliefs[0];
  } else {
    return null;
  }
};

export const selectOutcomeBeliefChange = (state, outcomeId) => {
  var outcome = selectOutcomeById(state, outcomeId);
  var sortedBeliefs = outcome?.beliefs?.toSorted(function (a, b) {
    return new Date(b.beliefDate) - new Date(a.BeliefDate);
  });
  if (sortedBeliefs?.length > 2) {
    return sortedBeliefs[0].belief - sortedBeliefs[2].belief;
  } else {
    return null;
  }
};

export const selectOutcomeEndDate = createSelector(
  [selectOutcomeById, (state, outcomeId) => outcomeId],
  (outcome) => {
    return outcome?.end_at;
  }
);

export const selectForecastsByQuestion = createSelector(
  [selectAllForecasts, (state, questionId) => questionId],
  (forecasts, questionId) =>
    forecasts
      .filter((forecast) => forecast.question_id === questionId)
      .sort((forecastA, forecastB) =>
        forecastA.created_at > forecastB.created_at
          ? 1
          : forecastB.created_at > forecastA.created_at
          ? -1
          : 0
      )
);

export const selectForecastsForTimeline = createSelector(
  [selectAllForecasts, (state, questionId) => questionId],
  (forecasts, questionId) => {
    const sortedForecasts = forecasts
      .filter((forecast) => forecast.question_id === questionId)
      .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

    return sortedForecasts?.map((forecast) => {
      return {
        x: new Date(forecast.created_at).toLocaleDateString('en-GB', {
          month: 'short',
          day: 'numeric'
        }),
        y: forecast.probability * 100
      };
    });
  }
);

export const selectCurrentCrowdForecast = createSelector(
  [selectQuestionById, (state, questionId) => questionId],
  (question) => {
    var sorted_crowd_forecasts = question.crowd_forecasts?.toSorted(
      (cfA, cfB) =>
        cfA.created_at < cfB.created_at
          ? 1
          : cfB.created_at < cfA.created_at
          ? -1
          : 0
    );
    return sorted_crowd_forecasts ? sorted_crowd_forecasts[0] : undefined;
  }
);

export const selectOutcomesSort = createSelector(
  (state) => state.outcomes,
  (state) => state.outcomes.sort
);

export const selectOutcomesFilters = createSelector(
  (state) => state.outcomes,
  (state) => state.outcomes.filters
);

export const selectOrderedFilteredOutcomes = (state, userId) => {
  const outcomes = selectFilteredOutcomes(state, userId);
  state = state.outcomes;
  if (state.outcomes.sort.type === 'created') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        outcomeB.created_at.localeCompare(outcomeA.created_at)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        outcomeA.created_at.localeCompare(outcomeB.created_at)
      );
    }
  } else if (state.outcomes.sort.type === 'endAt') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) => {
        if (outcomeA.end_at && outcomeB.end_at) {
          return outcomeA.end_at.localeCompare(outcomeB.end_at);
        } else if (
          (outcomeA.end_at === null || outcomeA.end_at === undefined) &&
          (outcomeB.end_at === null || outcomeB.end_at === undefined)
        ) {
          return 0;
        } else {
          return outcomeA.end_at ? 1 : -1;
        }
      });
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) => {
        if (outcomeA.end_at && outcomeB.end_at) {
          return outcomeB.end_at.localeCompare(outcomeA.end_at);
        } else if (
          (outcomeA.end_at === null || outcomeA.end_at === undefined) &&
          (outcomeB.end_at === null || outcomeB.end_at === undefined)
        ) {
          return 0;
        } else {
          return outcomeA.end_at ? -1 : 1;
        }
      });
    }
  } else if (state.outcomes.sort.type === 'activity') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeA.activity, outcomeB.activity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeB.activity, outcomeA.activity)
      );
    }
  } else if (state.outcomes.sort.type === 'recentActivity') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeA.recent_activity, outcomeB.recent_activity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeB.recent_activity, outcomeA.recent_activity)
      );
    }
  } else if (state.outcomes.sort.type === 'popular') {
    if (state.outcomes.sort.order === 'asc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeA.popularity, outcomeB.popularity)
      );
    } else if (state.outcomes.sort.order === 'desc') {
      outcomes.sort((outcomeA, outcomeB) =>
        floatPropertySort(outcomeB.popularity, outcomeA.popularity)
      );
    }
  }
  return outcomes;
};

const floatPropertySort = (activityA, activityB) => {
  if (activityA && activityB) {
    if (parseFloat(activityB) > parseFloat(activityA)) {
      return 1;
    }
    if (parseFloat(activityB) < parseFloat(activityA)) {
      return -1;
    }
    return 0;
  } else if (
    (activityA === null || activityA === undefined) &&
    (activityB === null || activityB === undefined)
  ) {
    return 0;
  } else {
    return activityA ? -1 : 1;
  }
};

export const selectFilteredOutcomes = createSelector(
  [
    (state) => state.outcomes,
    selectAllOutcomes,
    selectAllQuestions,
    selectAllEvaluations,
    (state, userId) => userId
  ],
  (state, outcomes, questions, evaluations, userId) => {
    if (state.outcomes.filters.own) {
      let filteredOutcomeIds = [];
      outcomes.forEach((outcome) => {
        if (outcome.created_by.id === userId) {
          filteredOutcomeIds.push(outcome.id);
        }
      });

      let ownQuestions = questions.filter(
        (question) => question.created_by.id === userId
      );
      ownQuestions.forEach((question) => {
        if (!filteredOutcomeIds.includes(question.outcome_id)) {
          filteredOutcomeIds.push(question.outcome_id);
        }
      });

      let ownEvaluations = evaluations.filter(
        (evaluation) => evaluation.user_id === userId
      );
      ownEvaluations.forEach((evaluation) => {
        questions.forEach((question) => {
          if (question.id === evaluation.question_id) {
            if (!filteredOutcomeIds.includes(question.outcome_id)) {
              filteredOutcomeIds.push(question.outcome_id);
            }
          }
        });
      });

      outcomes = outcomes.filter(
        (outcome) => !filteredOutcomeIds.includes(outcome.id)
      );
    }

    if (
      state.outcomes.filters.user.enabled &&
      state.outcomes.filters.user.username !== ''
    ) {
      outcomes = outcomes.filter(
        (outcome) =>
          outcome.created_by.username === state.outcomes.filters.user.username
      );
    }
    let generationOutcomes = [];
    let moderationOutcomes = [];
    let evaluationOutcomes = [];
    let forecastingOutcomes = [];
    let closedOutcomes = [];
    if (state.outcomes.filters.generation) {
      generationOutcomes = outcomes.filter((outcome) =>
        outcome.statuses.includes('Generation')
      );
    }
    if (state.outcomes.filters.moderation) {
      moderationOutcomes = outcomes.filter((outcome) =>
        outcome.statuses.includes('Moderation')
      );
    }
    if (state.outcomes.filters.evaluation) {
      evaluationOutcomes = outcomes.filter((outcome) =>
        outcome.statuses.includes('Evaluation')
      );
    }
    if (state.outcomes.filters.forecasting) {
      forecastingOutcomes = outcomes.filter((outcome) =>
        outcome.statuses.includes('Forecasting')
      );
    }
    if (state.outcomes.filters.closed) {
      closedOutcomes = outcomes.filter((outcome) =>
        outcome.statuses.includes('Closed')
      );
    }
    outcomes = generationOutcomes.concat(
      moderationOutcomes,
      evaluationOutcomes,
      forecastingOutcomes,
      closedOutcomes
    );
    outcomes = [
      ...new Map(outcomes.map((outcome) => [outcome['id'], outcome])).values()
    ];
    return outcomes;
  }
);

export const selectQuestionsSortQuestionScore = createSelector(
  (state) => state.outcomes,
  (state) => state.questions.sort.question_score_table
);
export const selectQuestionsSortEvaluationScore = createSelector(
  (state) => state.outcomes,
  (state) => state.questions.sort.evaluation_score_table
);

export const selectFilteredQuestionsByOutcomeGlobal = createSelector(
  [
    (state) => state.outcomes,
    selectAllQuestions,
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    return selectFilteredQuestionsByOutcome(
      questions,
      outcomeId,
      state.outcomes.entities[outcomeId].questionFilter,
      state.outcomes.entities[outcomeId].questionEvaluationFilter
    );
  }
);

export const selectSortedFilteredQuestionsByOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllQuestions,
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    let filteredQuestions = selectFilteredQuestionsByOutcome(
      questions,
      outcomeId,
      state.outcomes.entities[outcomeId].questionFilter,
      state.outcomes.entities[outcomeId].questionEvaluationFilter
    );
    let sortedQuestions = filteredQuestions;
    if (state.outcomes.entities[outcomeId].questionSorter === 1) {
      //created_on
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          if (questionA.created_at && questionB.created_at) {
            return questionB.created_at.localeCompare(questionA.created_at);
          }
          if (
            (questionA.created_at === null ||
              questionA.created_at === undefined) &&
            (questionB.created_at === null ||
              questionB.created_at === undefined)
          ) {
            return 0;
          } else {
            return questionA.created_at ? -1 : 1;
          }
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].questionSorter === 2) {
      //forecast
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          return floatPropertySort(
            questionA.current_crowd_forecast,
            questionB.current_crowd_forecast
          );
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].questionSorter === 3) {
      //relevance
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          return floatPropertySort(
            questionA.relevance_level,
            questionB.relevance_level
          );
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].questionSorter === 4) {
      //impact
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          return floatPropertySort(
            Math.abs(questionA.current_impact),
            Math.abs(questionB.current_impact)
          );
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].questionSorter === 5) {
      //impact
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          return floatPropertySort(
            Math.abs(questionA.weekly_forecast_change),
            Math.abs(questionB.weekly_forecast_change)
          );
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].questionSorter === 6) {
      //impact
      sortedQuestions = sortedQuestions.sort((questionA, questionB) => {
        if (questionA.outcome_as_question === questionB.outcome_as_question) {
          return (
            Math.abs(questionA.current_crowd_forecast - 0.5) -
            Math.abs(questionB.current_crowd_forecast - 0.5)
          );
        } else {
          return questionA.outcome_as_question ? -1 : 1;
        }
      });
    }

    return sortedQuestions;
  }
);

export const selectFilteredQuestionsByOutcomeWithoutSelf = (
  state,
  outcomeId,
  questionFilter = {},
  userId
) => {
  const outcomeEntities = state.outcomes.outcomes.entities || {};
  const outcome = outcomeEntities[outcomeId] || {};

  const questionEvaluationFilter = outcome.questionEvaluationFilter || {};

  const filteredQuestions =
    selectCustomFilteredQuestionsbyOutcome(
      state,
      outcomeId,
      questionFilter,
      questionEvaluationFilter
    ) || [];

  return filteredQuestions.filter(
    (question) =>
      question && question.created_by && question.created_by.id !== userId
  );
};

export const selectSortedStrategiesByOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllStrategies,
    (state, outcomeId) => outcomeId
  ],
  (state, strategies, outcomeId) => {
    let outcomeStrateies = strategies.filter(
      (strategy) => strategy.outcome_id === outcomeId
    );
    let sortedStrategies = outcomeStrateies;
    if (state.outcomes.entities[outcomeId].strategySorter === 1) {
      //created_on
      sortedStrategies = sortedStrategies.sort((strategyA, strategyB) => {
        if (strategyA.created_at && strategyB.created_at) {
          return strategyB.created_at.localeCompare(strategyA.created_at);
        }
        if (
          (strategyA.created_at === null ||
            strategyA.created_at === undefined) &&
          (strategyB.created_at === null || strategyB.created_at === undefined)
        ) {
          return 0;
        } else {
          return strategyA.created_at ? -1 : 1;
        }
      });
    }

    if (state.outcomes.entities[outcomeId].strategySorter === 2) {
      //forecast
      sortedStrategies = sortedStrategies.sort((strategyA, strategyB) => {
        return floatPropertySort(
          strategyA.current_likelihood_prediction.probability.p_value,
          strategyB.current_likelihood_prediction.probability.p_value
        );
      });
    }

    if (state.outcomes.entities[outcomeId].strategySorter === 3) {
      //relevance
      sortedStrategies = sortedStrategies.sort((strategyA, strategyB) => {
        return floatPropertySort(
          strategyA.relevance_level,
          strategyB.relevance_level
        );
      });
    }

    if (state.outcomes.entities[outcomeId].strategySorter === 4) {
      //impact
      sortedStrategies = sortedStrategies.sort((strategyA, strategyB) => {
        return floatPropertySort(
          strategyA.current_impact,
          strategyB.current_impact
        );
      });
    }

    return sortedStrategies;
  }
);

export const selectCustomFilteredQuestionsbyOutcome = createSelector(
  [
    (state) => state.outcomes,
    selectAllQuestions,
    (state, outcomeId) => outcomeId,
    (state, outcomeId, questionFilter) => questionFilter,
    (questionEvaluationFilter) => questionEvaluationFilter
  ],
  (state, questions, outcomeId, questionFilter, questionEvaluationFilter) =>
    selectFilteredQuestionsByOutcome(
      questions,
      outcomeId,
      questionFilter,
      questionEvaluationFilter
    )
);

export const selectFilteredQuestionsByOutcome = (
  questions = [],
  outcomeId,
  questionFilter = {},
  questionEvaluationFilter = {}
) => {
  const allQuestions = (questions || []).filter(
    (question) => question && question.outcome_id === outcomeId
  );
  let outputQuestions = [];
  let statusFilteredQuestions = [];

  if (questionFilter.accepted) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Accepted'
      )
    );
  }
  if (questionFilter.submitted) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) =>
          question &&
          (question.status === 'Submitted' || question.status === 'Completed')
      )
    );
  }
  if (questionFilter.closed) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Closed'
      )
    );
  }
  if (questionFilter.pending) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Pending'
      )
    );
  }
  if (questionFilter.rejected) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Rejected'
      )
    );
  }
  if (questionFilter.notSubmitted) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Not Submitted'
      )
    );
  }
  if (questionFilter.duplicate) {
    statusFilteredQuestions = statusFilteredQuestions.concat(
      allQuestions.filter(
        (question) => question && question.status === 'Duplicate'
      )
    );
  }

  outputQuestions = filterQuestionsByEvaluation(
    statusFilteredQuestions,
    questionEvaluationFilter
  );

  return outputQuestions;
};

export const selectQuestionFilter = createSelector(
  [(state) => state.outcomes, (state, outcomeId) => outcomeId],
  (state, outcomeId) => state.outcomes.entities[outcomeId].questionFilter
);

export const selectQuestionEvaluationFilter = createSelector(
  [(state) => state.outcomes, (state, outcomeId) => outcomeId],
  (state, outcomeId) =>
    state.outcomes.entities[outcomeId].questionEvaluationFilter
);

export const selectEvaluationByQuestionUser = (state, questionId, userId) => {
  const evaluations = selectAllEvaluations(state);
  const evaluation = evaluations.find(
    (evaluation) =>
      evaluation.user_id === userId && evaluation.question_id === questionId
  );
  return evaluation ? evaluation : null;
};

export const selectRelevanceEvaluationsByQuestionUser = (
  state,
  questionId,
  userId
) => {
  const evaluations = selectAllRelevanceEvaluations(state);
  const filteredEvaluations = evaluations.filter(
    (evaluation) =>
      evaluation.user_id === userId && evaluation.question_id === questionId
  );
  return filteredEvaluations ? filteredEvaluations : [];
};

export const selectRelevanceEvaluationsByStrategyUser = (
  state,
  strategyId,
  userId
) => {
  const evaluations = selectAllRelevanceEvaluations(state);
  const filteredEvaluations = evaluations.filter(
    (evaluation) =>
      evaluation.user_id === userId && evaluation.strategy_id === strategyId
  );
  return filteredEvaluations ? filteredEvaluations : [];
};

export const selectEvaluationsByUserId = createSelector(
  [selectAllEvaluations, (state, userId) => userId],
  (evaluations, userId) =>
    evaluations.filter((evaluation) => evaluation.user_id === userId)
);
export const selectOutcomeExplanationsByOutcomeId = createSelector(
  [selectAllOutcomeExplanations, (state, outcomeId) => outcomeId],
  (explanations, outcomeId) =>
    explanations.filter((explanations) => explanations.outcome_id === outcomeId)
);

export const selectHasUserCommented = (state, questionId, username) => {
  const commentIds = selectStandardCommentIdsByQuestion(state, questionId);
  const comments = selectAllComments(state);
  const filteredComments = comments.filter((comment) =>
    commentIds.includes(comment.id)
  );
  const userComment = filteredComments.find(
    (comment) => comment.created_by.username === username
  );

  if (userComment !== undefined) {
    return true;
  } else {
    return false;
  }
};

function relationiseCommentReplies(comment) {
  if (comment.replies.length <= 0) {
    return comment;
  } else {
    comment.replies.forEach((reply) => {
      if (reply.is_deleted !== undefined) {
        reply.is_deleted = reply.is_deleted === 'True' ? true : false;
      }
      reply.parentId = comment.id;
      relationiseCommentReplies(reply);
    });
    return comment;
  }
}

function addQuestionReferenceToReplies(replies, questionId) {
  replies.forEach((reply) => {
    reply.questionId = questionId;
    if (reply.replies.length > 0) {
      addQuestionReferenceToReplies(reply.replies, questionId);
    }
  });
  return replies;
}

function addStrategyReferenceToReplies(replies, strategyId) {
  replies.forEach((reply) => {
    reply.strategyId = strategyId;
    if (reply.replies.length > 0) {
      addStrategyReferenceToReplies(reply.replies, strategyId);
    }
  });
  return replies;
}

function flattenComment(comment) {
  let flatComments = [comment];
  comment.replies.forEach((reply) => {
    flatComments.push(...flattenReply(reply));
  });
  flatComments.forEach((comment) => {
    delete comment.replies;
  });
  return flatComments;
}

function flattenReply(reply) {
  const flat = [reply];

  reply.replies.forEach((innerReply) => {
    if (Array.isArray(innerReply.replies)) {
      flat.push(...flattenReply(innerReply));
    } else {
      flat.push(innerReply);
    }
  });
  return flat;
}

function sort_by_created_modified(a, b) {
  let a_val = a.modified_at ? a.modified_at : a.created_at;
  let b_val = b.modified_at ? b.modified_at : b.created_at;
  return b_val.localeCompare(a_val);
}

const getPlainText = (text) => {
  return text.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const selectOrderedFilteredQuestionRanks = (state, userId, filter) => {
  if (state.outcomes.selectedOutcome) {
    var selectedOutcome = selectOutcomeById(
      state,
      state.outcomes.selectedOutcome.id
    );
  }
  const questions = selectFilteredQuestions(state, filter);
  var questionsCopy = [];
  var unsortable = [];
  var evaluation = {};
  questions.forEach(function (question, index) {
    evaluation = selectEvaluationByQuestionUser(state, question.id, userId);
    // remove from questions array and add to unsortable array if user hasn't evaluated
    if (!evaluation) {
      if (
        question.created_by.id === userId ||
        (selectedOutcome && selectedOutcome.created_by.id === userId)
      ) {
        questionsCopy.push(question);
      } else {
        unsortable.push(question);
      }
    } else {
      questionsCopy.push(question);
    }
  });
  var questionRanks = [];
  if (filter === 'question_score') {
    if (questionsCopy.length > 0 && questionsCopy[0].rank === undefined) {
      questionsCopy.sort((questionA, questionB) =>
        floatPropertySort(questionA.question_score, questionB.question_score)
      );
      questionsCopy.forEach(function (question, index) {
        questionRanks.push({ questionId: question.id, rank: index });
      });
    }
  } else if (filter === 'evaluation_score') {
    if (questionsCopy.length > 0 && questionsCopy[0].rank === undefined) {
      questionsCopy.sort((questionA, questionB) =>
        floatPropertySort(
          questionA.average_evaluation,
          questionB.average_evaluation
        )
      );
      questionsCopy.forEach(function (question, index) {
        questionRanks.push({ questionId: question.id, rank: index });
      });
    }
  }
  var questionRanksLength = questionRanks.length;
  // add unsortable questions on at the end
  for (let i = 0; i < unsortable.length; i++) {
    questionRanks.push({
      questionId: unsortable[i].id,
      rank: questionRanksLength + i
    });
  }
  return questionRanks;
};

export const selectOrderedFilteredQuestions = (state, userId, filter) => {
  const questions = selectFilteredQuestions(state, filter);
  const questionRanks = selectOrderedFilteredQuestionRanks(
    state,
    userId,
    filter
  );
  const evaluations = selectAllEvaluations(state);
  state = state.outcomes;
  if (filter === 'question_score') {
    if (state.questions.sort.question_score_table.type === 'rank') {
      if (state.questions.sort.question_score_table.order === 'asc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(
            questionRanks.map((e) => e.questionId).indexOf(questionA.id),
            questionRanks.map((e) => e.questionId).indexOf(questionB.id)
          )
        );
      } else if (state.questions.sort.question_score_table.order === 'desc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(
            questionRanks.map((e) => e.questionId).indexOf(questionB.id),
            questionRanks.map((e) => e.questionId).indexOf(questionA.id)
          )
        );
      }
    } else if (
      state.questions.sort.question_score_table.type === 'question_text'
    ) {
      if (state.questions.sort.question_score_table.order === 'asc') {
        questions.sort((questionA, questionB) =>
          questionB.question_text.localeCompare(questionA.question_text)
        );
      } else if (state.questions.sort.question_score_table.order === 'desc') {
        questions.sort((questionA, questionB) =>
          questionA.question_text.localeCompare(questionB.question_text)
        );
      }
    } else if (
      state.questions.sort.question_score_table.type === 'question_score'
    ) {
      if (state.questions.sort.question_score_table.order === 'asc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(questionB.question_score, questionA.question_score)
        );
      } else if (state.questions.sort.question_score_table.order === 'desc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(questionA.question_score, questionB.question_score)
        );
      }
    } else if (
      state.questions.sort.question_score_table.type === 'user_evaluation_score'
    ) {
      var questionsCopy = [];
      var unsortable = [];
      var evaluation = {};
      questions.forEach(function (question, index) {
        evaluation = evaluations.find(
          (evaluation) =>
            evaluation.user_id === userId &&
            evaluation.question_id === question.id
        );
        // remove from questions array and add to unsortable array if user hasn't evaluated
        if (!evaluation) {
          // if (question.created_by.id === userId) {
          //   questionsCopy.push(question)
          // } else {
          unsortable.push(question);
          // }
        } else {
          questionsCopy.push(question);
        }
      });

      // sort by asc or desc
      if (state.questions.sort.question_score_table.order === 'asc') {
        questionsCopy.sort((questionA, questionB) => {
          const userEvaluationA = evaluations.find(
            (evaluation) =>
              evaluation.user_id === userId &&
              evaluation.question_id === questionA.id
          );
          const userEvaluationB = evaluations.find(
            (evaluation) =>
              evaluation.user_id === userId &&
              evaluation.question_id === questionB.id
          );
          return floatPropertySort(
            userEvaluationB.evaluation_score_category.value,
            userEvaluationA.evaluation_score_category.value
          );
        });
        // add unsortable questions on at the start
        for (let i = 0; i < unsortable.length; i++) {
          questionsCopy.unshift(unsortable[i]);
        }
      } else if (state.questions.sort.question_score_table.order === 'desc') {
        questionsCopy.sort((questionA, questionB) => {
          const userEvaluationA = evaluations.find(
            (evaluation) =>
              evaluation.user_id === userId &&
              evaluation.question_id === questionA.id
          );
          const userEvaluationB = evaluations.find(
            (evaluation) =>
              evaluation.user_id === userId &&
              evaluation.question_id === questionB.id
          );
          return floatPropertySort(
            userEvaluationA.evaluation_score_category.value,
            userEvaluationB.evaluation_score_category.value
          );
        });
        // add unsortable questions on at the end
        for (let i = 0; i < unsortable.length; i++) {
          questionsCopy.push(unsortable[i]);
        }
      }
      return questionsCopy;
    }
  } else if (filter === 'evaluation_score') {
    if (state.questions.sort.evaluation_score_table.type === 'rank') {
      if (state.questions.sort.evaluation_score_table.order === 'asc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(
            questionRanks.map((e) => e.questionId).indexOf(questionA.id),
            questionRanks.map((e) => e.questionId).indexOf(questionB.id)
          )
        );
      } else if (state.questions.sort.evaluation_score_table.order === 'desc') {
        questions.sort((questionA, questionB) =>
          floatPropertySort(
            questionRanks.map((e) => e.questionId).indexOf(questionB.id),
            questionRanks.map((e) => e.questionId).indexOf(questionA.id)
          )
        );
      }
    } else if (
      state.questions.sort.evaluation_score_table.type === 'question_text'
    ) {
      if (state.questions.sort.evaluation_score_table.order === 'asc') {
        questions.sort((questionA, questionB) =>
          questionB.question_text.localeCompare(questionA.question_text)
        );
      } else if (state.questions.sort.evaluation_score_table.order === 'desc') {
        questions.sort((questionA, questionB) =>
          questionA.question_text.localeCompare(questionB.question_text)
        );
      }
    } else if (
      state.questions.sort.evaluation_score_table.type ===
      'user_evaluation_score'
    ) {
      if (state.outcomes.selectedOutcome) {
        var selectedOutcome = selectOutcomeById(
          state,
          state.outcomes.selectedOutcome.id
        );
      }
      var questionsCopy = [];
      var unsortable = [];
      var evaluation = {};
      questions.forEach(function (question, index) {
        evaluation = evaluations.find(
          (evaluation) =>
            evaluation.user_id === userId &&
            evaluation.question_id === question.id
        );
        // remove from questions array and add to unsortable array if user hasn't evaluated
        if (!evaluation) {
          if (
            question.created_by.id === userId ||
            (selectedOutcome && selectedOutcome.created_by.id === userId)
          ) {
            // if (question.created_by.id === userId) {
            questionsCopy.push(question);
          } else {
            unsortable.push(question);
          }
        } else {
          questionsCopy.push(question);
        }
      });

      // sort by asc or desc
      if (state.questions.sort.evaluation_score_table.order === 'asc') {
        questionsCopy.sort((questionA, questionB) =>
          floatPropertySort(
            questionB.average_evaluation,
            questionA.average_evaluation
          )
        );
        // add unsortable questions on at the start
        for (let i = 0; i < unsortable.length; i++) {
          questionsCopy.unshift(unsortable[i]);
        }
      } else if (state.questions.sort.evaluation_score_table.order === 'desc') {
        questionsCopy.sort((questionA, questionB) =>
          floatPropertySort(
            questionA.average_evaluation,
            questionB.average_evaluation
          )
        );
        // add unsortable questions on at the end
        for (let i = 0; i < unsortable.length; i++) {
          questionsCopy.push(unsortable[i]);
        }
      }
      return questionsCopy;
    }
  }
  return questions;
};

export const selectFilteredQuestions = createSelector(
  [(state) => state, selectAllQuestions, (state, filter) => filter],
  (state, questions, filter) => {
    if (filter === 'question_score') {
      let filteredQuestionIds = [];
      questions.forEach((question) => {
        if (
          question.question_score !== undefined &&
          question.status !== 'Rejected' &&
          state.outcomes.selectedOutcome &&
          question.outcome_id === state.outcomes.selectedOutcome.id
        ) {
          filteredQuestionIds.push(question.id);
        }
      });
      questions = questions.filter((questions) =>
        filteredQuestionIds.includes(questions.id)
      );
    }

    if (filter === 'evaluation_score') {
      let filteredQuestionIds = [];
      questions.forEach((question) => {
        if (
          question.average_evaluation !== undefined &&
          question.status !== 'Rejected' &&
          state.outcomes.selectedOutcome &&
          question.outcome_id === state.outcomes.selectedOutcome.id &&
          question.question_score === undefined
        ) {
          filteredQuestionIds.push(question.id);
        }
      });
      questions = questions.filter((questions) =>
        filteredQuestionIds.includes(questions.id)
      );
    }

    if (state.outcomes.selectedOutcome && state.outcomes.outcomes.entities) {
      var questionEvaluationFilter =
        state.outcomes.outcomes.entities[state.outcomes.selectedOutcome.id]
          .questionEvaluationFilter;
      questions = filterQuestionsByEvaluation(
        questions,
        questionEvaluationFilter
      );
    }
    return questions;
  }
);

export const selectPositiveImpactQuestions = createSelector(
  [
    (state) => state.outcomes,
    (state, outcomeId) => selectQuestionsByOutcome(state, outcomeId),
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    return questions.filter((question) =>
      question.relevance_level ? question.relevance_level > 0 : false
    );
  }
);

export const selectNegativeImpactQuestions = createSelector(
  [
    (state) => state.outcomes,
    (state, outcomeId) => selectQuestionsByOutcome(state, outcomeId),
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    return questions.filter((question) =>
      question.relevance_level ? question.relevance_level < 0 : false
    );
  }
);

export const selectProcessedCrowdForecasts = createSelector(
  [
    (state) => state.outcomes,
    (state, outcomeId) => selectQuestionsByOutcome(state, outcomeId),
    (state, outcomeId) => outcomeId
  ],
  (state, questions, outcomeId) => {
    let selectedQuestionIDs = state.outcomes?.selectedDashboardQuestionIDs;
    let useOther = state.outcomes?.showDashboardOthers;
    if (!selectedQuestionIDs || selectedQuestionIDs.length === 0) {
      if (questions && questions.length > 0) {
        let sortedQuestions = [...questions].sort((questionA, questionB) => {
          // Handle null/undefined cases
          const value1Avg =
            questionA.daily_forecast_changes?.reduce(
              (total, next) => total + next.forecast_change,
              0
            ) / questionA.daily_forecast_changes?.length;
          const value2Avg =
            questionB.daily_forecast_changes?.reduce(
              (total, next) => total + next.forecast_change,
              0
            ) / questionB.daily_forecast_changes?.length;
          return Math.abs(value2Avg) - Math.abs(value1Avg);
        });
        // Remove the question with the lowest id
        sortedQuestions = sortedQuestions.filter(
          (question) =>
            question.id !== Math.min(...sortedQuestions.map((q) => q.id))
        );
        selectedQuestionIDs = sortedQuestions.slice(0, 5).map((q) => q.id);
      }
    }
    const otherQuestions = questions.filter(
      (question) => !selectedQuestionIDs.includes(question.id)
    );

    if (!questions || questions.length === 0) return [];

    // Collect all crowd forecasts
    const allCrowdForecasts = questions.flatMap((q) =>
      q.crowd_forecasts.map((forecast) => ({
        ...forecast,
        questionId: q.id,
        questionTitle: q.title || `Question ${q.id}`
      }))
    );

    // Sort forecasts by creation date
    const sortedForecasts = allCrowdForecasts.sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at)
    );

    // Create unique dates
    const uniqueDates = [
      ...new Set(
        sortedForecasts.map((forecast) =>
          new Date(forecast.created_at).toLocaleDateString('en-US', {
            month: 'short',
            day: 'numeric'
          })
        )
      )
    ];

    // Process forecasts by question
    const seriesByQuestion = {};
    questions.forEach((question) => {
      if (!selectedQuestionIDs.includes(question.id)) return;
      const questionForecasts = sortedForecasts.filter(
        (f) => f.questionId === question.id
      );

      const seriesData = uniqueDates.map((date) => {
        const matchingForecast = questionForecasts
          .filter(
            (f) =>
              new Date(f.created_at).toLocaleDateString('en-US', {
                month: 'short',
                day: 'numeric'
              }) <= date
          )
          .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

        return {
          x: date,
          y: matchingForecast
            ? matchingForecast.probability * 100
            : questionForecasts.length > 0
            ? questionForecasts[questionForecasts.length - 1].probability * 100
            : 50
        };
      });

      seriesByQuestion[question.id] = {
        id: `question-${question.id}`,
        label: question.question_text.substring(0, 32) + '..',
        data: seriesData
      };
    });

    // Process 'other' category
    if (useOther && otherQuestions.length > 0) {
      const otherSeriesData = uniqueDates.map((date) => {
        const totalProbability = otherQuestions.reduce((total, question) => {
          const questionForecasts = sortedForecasts.filter(
            (f) => f.questionId === question.id
          );

          const matchingForecast = questionForecasts
            .filter(
              (f) =>
                new Date(f.created_at).toLocaleDateString('en-US', {
                  month: 'short',
                  day: 'numeric'
                }) <= date
            )
            .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];

          const probability = matchingForecast
            ? matchingForecast.probability * 100
            : questionForecasts.length > 0
            ? questionForecasts[questionForecasts.length - 1].probability * 100
            : 50;

          return total + probability;
        }, 0);

        return {
          x: date,
          y: totalProbability
        };
      });

      seriesByQuestion['other'] = {
        id: 'other',
        label: 'Other',
        data: otherSeriesData
      };
    }

    return Object.values(seriesByQuestion);
  }
);

function filterQuestionsByEvaluation(questions, questionEvaluationFilter) {
  if (
    questionEvaluationFilter.veryLow ||
    questionEvaluationFilter.low ||
    questionEvaluationFilter.medium ||
    questionEvaluationFilter.high ||
    questionEvaluationFilter.veryHigh
  ) {
    if (questionEvaluationFilter.veryLow) {
      questions = questions.filter(
        (question) => question.average_evaluation_category.value === 1
      );
    }
    if (questionEvaluationFilter.low) {
      questions = questions.filter(
        (question) => question.average_evaluation_category.value === 2
      );
    }
    if (questionEvaluationFilter.medium) {
      questions = questions.filter(
        (question) => question.average_evaluation_category.value === 3
      );
    }
    if (questionEvaluationFilter.high) {
      questions = questions.filter(
        (question) => question.average_evaluation_category.value === 4
      );
    }
    if (questionEvaluationFilter.veryHigh) {
      questions = questions.filter(
        (question) => question.average_evaluation_category.value === 5
      );
    }
  }
  return questions;
}

export const getOutcomeSubmissionLimit = (state) => {
  if (state.outcomes.selectedOutcome) {
    var selectedOutcome = selectOutcomeById(
      state,
      state.outcomes.selectedOutcome.id
    );
  }
  if (selectedOutcome.question_submission_limit) {
    return selectedOutcome.question_submission_limit;
  } else {
    return 0;
  }
};
