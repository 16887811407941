import { Button, Card, Checkbox, Divider, Typography, TextField, Backdrop } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import {
  selectOutcomesFilters,
  toggleOutcomeFilters
} from '../../store/slices/outcomeSlice';
import { selectAllUsers, fetchUsers } from '../../store/slices/userSlice';
import OutcomeStatusFilter from '../other/OutcomeStatusFilter';
import Autocomplete from '@mui/material/Autocomplete';

export default function FilterModal({ shown, close }) {
  const closeModal = () => {
    close();
  };
  const outcomeFilters = useSelector((state) => selectOutcomesFilters(state));
  const [inputValue, setInputValue] = useState('');
  const [user, setUser] = useState(null);
  const users = useSelector((state) => selectAllUsers(state));

  const dispatch = useDispatch();

  const usersStatus = useSelector((state) => state.users.status);

  useEffect(() => {
    if (usersStatus === 'idle') {
      const token = localStorage.getItem('auth_token');
      dispatch(fetchUsers(token));
    }
  }, [usersStatus, dispatch]);

  useEffect(() => {
    if (shown) {
      const handleEscape = (event) => {
        if (event.key === 'Escape') {
          close();
        }
      };
      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [shown, close]);
  
  const handleFilterChecked = (event) => {
    const filter = event.target.value;
    if (filter === 'own') {
      dispatch(toggleOutcomeFilters({ toggleUnseen: true }));
    } else if (filter === 'user') {
      dispatch(toggleOutcomeFilters({ toggleUserEnabled: true }));
    } else {
      throw new Error('Incorrect parameter.');
    }
  };

  const handleOnSelect = (item) => {
    setUser(item);
    dispatch(toggleOutcomeFilters({ userUsername: item.username }));
  };


  return shown ? (
    <div className="modal-backdrop" onClick={() => {}}>
      <Backdrop
        sx={{
          zIndex: 999,
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        open={true}
        onClick={() => close()}>   
        <Card
          className="modal-content w-full sm:w-5/6 md:w-2/3 lg:w-3/5 xl:w-2/5 2xl:w-1/3"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          <Typography
              sx={{ fontWeight: 700, fontSize: '1.5rem' }}
              className="text-center">
            Filter
          </Typography>
          <Divider sx={{ mt: 1, mb: 0.5}}>
            Outcome Status
          </Divider>
          <OutcomeStatusFilter />
          <Divider sx={{ mt: 1}}>
            My Participation
          </Divider>
          <div className="items-center flex mt-1 mb-1">
            <Checkbox
              sx={{ mr: 1, p: 0 }}
              name="filterCheckboxes"
              id="ownCheckbox"
              checked={outcomeFilters.own}
              onChange={handleFilterChecked}
              value="own"
            />
            <Typography sx={{ mr: 1 }}>Unseen</Typography>
          </div>
          <Divider sx={{ mt: 1}}>
            Other
          </Divider>
          <div className="items-center flex mt-2">
            <Checkbox
              sx={{ mr: 1, p: 0 }}
              name="filterCheckboxes"
              id="userCheckbox"
              checked={outcomeFilters.user.enabled}
              onChange={handleFilterChecked}
              value="user"
            />
            <Typography sx={{ mr: 1 }}>Author Lookup:</Typography>
            <div className="w-3/5">
              <Autocomplete
                autoHighlight
                options={users}
                clearOnBlur={false}
                value={user}
                onChange={(event, newValue) => {
                  handleOnSelect(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                getOptionLabel={(user) => user.username}
                renderInput={(params) =>
                  <div className="flex flex-row items-center">
                    <TextField {...params} label="Username" />
                    <SearchIcon
                      sx={{ color: 'action.active', mx: 1.5 }}
                      id='search-button'
                      onClick={() => handleOnSelect(users.find((user) => user.username == inputValue))} />
                  </div>
                }
              />
            </div>
          </div>
          <div className="m-2 flex justify-end">
            <Button variant="contained" onClick={closeModal} className="">
              Close
            </Button>
          </div>
        </Card>
      </Backdrop>
    </div>
  ) : null;
}
